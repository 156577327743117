import React, {useState, useEffect, FC} from 'react'
import {toAbsoluteUrl} from '../../helpers'
import axios from 'axios'
import {toast} from 'react-toastify'
import {Button, Dropdown} from 'react-bootstrap'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Swal from 'sweetalert2'
import ReactTooltip from 'react-tooltip'
import {Link} from 'react-router-dom'
import AddUpdateItemModal from './AddUpdateItemModal'
import AWS from 'aws-sdk'

const CustomToggle = React.forwardRef(
  ({children, onClick, disabled}: any, ref: React.ForwardedRef<HTMLButtonElement>) => (
    <button
      disabled={disabled}
      className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary btn-light-primary'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </button>
  )
)

interface Props {
  formObject: any
}

const ManageItem: FC<Props> = ({formObject}) => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([{}, {}, {}, {}, {}] as Array<any>)
  const [itemToEdit, setItemToEdit] = useState<any>()
  const [showItemModal, setShowItemModal] = useState<boolean>(false)
  const [start, setStart] = useState({})
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>()

  const editItem = (item: any) => {
    setItemToEdit(item)
    setShowItemModal(true)
  }

  const handleEditItemClose = () => {
    setShowItemModal(false)
    setItemToEdit(undefined)
  }

  let downloadFile = (url: string) => {
    console.log('url', url)
    let urlArray = url.split('/')
    let bucket = urlArray[3]
    let key = `${urlArray[4]}/${urlArray[5]}`
    let s3 = new AWS.S3({params: {Bucket: bucket}})
    let params = {Bucket: bucket, Key: key}
    s3.getObject(params, (err: any, data: any) => {
      if (err) {
        console.log(err)
        return
      }
      let blob = new Blob([data.Body], {type: data.ContentType})
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = url
      link.click()
    })
  }

  const getStatusCssClassColor = (status: string) => {
    let cssClass
    switch (status) {
      case 'Active':
      case 'success':
      case 'Completed':
        cssClass = 'success'
        break

      case 'Disabled':
      case 'Canceled':
        cssClass = 'danger'
        break

      case 'Declined':
        cssClass = 'danger'
        break

      case 'Pending':
      case 'In Progress':
      case 'PendingApproval':
        cssClass = 'warning'
        break

      case 'Not Started':
        cssClass = 'dark'
        break

      default:
        cssClass = 'dark'
        break
    }
    return cssClass
  }

  const getElementToReturn = (tableObject: any, fieldValue: any, item: any) => {
    const type = tableObject.type
    let elementToReturn
    // Return "-" when there is no element

    if (!fieldValue) {
      return <span className='fw-bold'>{fieldValue === 0 ? fieldValue : '-'}</span>
    }

    switch (type) {
      case 'status':
        elementToReturn = (
          <span className={`badge badge-light-${getStatusCssClassColor(fieldValue)}`}>
            {fieldValue}
          </span>
        )
        break

      case 'tag':
        if (typeof fieldValue === 'object') {
          elementToReturn = (
            <>
              {fieldValue?.map((field: any) => (
                <span className='badge badge-light-primary me-2'>{field}</span>
              ))}
            </>
          )
        } else {
          elementToReturn = <span className='badge badge-light-primary me-2'>{fieldValue}</span>
        }
        break

      case 'date':
        elementToReturn = <span>{moment(fieldValue).format('MMM D, YYYY')}</span>
        break

      case 'datetime':
        elementToReturn = <span>{moment(fieldValue).format('MMM D, YYYY h:mm a')}</span>
        break

      case 'link':
        elementToReturn = (
          <Link to={`${tableObject.path}/${item.id}`} className='fw-bold text-hover-primary mb-1'>
            {fieldValue}
          </Link>
        )
        break

      case 'external-link':
        elementToReturn = (
          <a
            onClick={() => downloadFile(item[tableObject.urlField])}
            className='fw-bold text-hover-primary mb-1'
          >
            {fieldValue}
          </a>
        )
        break

      case 'currency':
        elementToReturn = (
          <span>
            {tableObject?.symbol}{' '}
            {typeof fieldValue === 'number' ? fieldValue.toFixed(2) : fieldValue}
          </span>
        )
        break

      default:
        elementToReturn = <span> {typeof fieldValue !== 'object' ? fieldValue : '-'}</span>
        break
    }
    return elementToReturn
  }

  const getFormattedItem = (item: any, tableObject: any) => {
    let fieldValue

    if (tableObject.name.includes('[]')) {
      const nestedObjects = tableObject.name.split('[].')

      fieldValue = item[nestedObjects[0]]

      return (
        <>
          {fieldValue?.map((item: any) => {
            return getElementToReturn(tableObject, item[nestedObjects[1]], item)
          })}
        </>
      )
    } else if (tableObject.name.includes('.')) {
      const nestedObjects = tableObject.name.split('.')

      if (nestedObjects.length === 2) {
        fieldValue = item[nestedObjects[0]]
        const value = fieldValue ? fieldValue[nestedObjects[1]] : ''
        return getElementToReturn(tableObject, value, item)
      }
    } else {
      fieldValue = item[tableObject.name]
      return getElementToReturn(tableObject, fieldValue, item)
    }

    return getElementToReturn(tableObject, fieldValue, item)
  }

  function capitalizeFirstLetter(inputString: string) {
    return inputString.charAt(0).toUpperCase() + encodeURIComponent(inputString.slice(1))
  }

  const getItems = async (inputLastEvaluatedKey?: any) => {
    if (!inputLastEvaluatedKey) setLoading(true)

    let queryString = ''
    if (inputLastEvaluatedKey) {
      Object.keys(inputLastEvaluatedKey).map((item, index) => {
        queryString = `start${capitalizeFirstLetter(item)}=${encodeURIComponent(
          inputLastEvaluatedKey[item]
        )}${index < Object.keys(inputLastEvaluatedKey).length ? '&' : ''}${queryString}`
      })
    }

    try {
      const response = await axios.get(`${formObject.listEndpoint}?${queryString}`)

      if ((items && items[0] && !items[0].id) || !inputLastEvaluatedKey) {
        items.length = 0
      }
      items.push(...response?.data?.data)
      setItems([...items])

      //setItems(response?.data?.data);
      setLastEvaluatedKey(response?.data?.lastEvaluatedKey)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const e = {...error}
      const err = e?.response?.data?.error
      console.log('ereqd', err)
      toast.error('err', err?.message)
    }
  }

  useEffect(() => {
    if (!items || !items[0].id) {
      getItems()
    }
  }, [])

  return (
    <>
      <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
        <div className='card-title d-flex justify-content-between w-100'>
          <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
            {formObject.listTitle}
          </h1>
          {formObject?.addEndpoint && (
            <div className=''>
              <Button onClick={() => editItem({})} className='btn btn-sm btn-primary me-3'>
                Add
              </Button>
            </div>
          )}
        </div>
      </div>

      <InfiniteScroll
        dataLength={items?.length} //This is important field to render the next data
        next={() => {
          if (lastEvaluatedKey) getItems(lastEvaluatedKey)
        }}
        hasMore={lastEvaluatedKey}
        loader={<h4 className='p-3 text-center text-primary'>Loading...</h4>}
        endMessage={
          loading ? null : (
            <p className='text-muted' style={{textAlign: 'center'}}>
              <b>Yay! You have seen it all</b>
            </p>
          )
        }
      >
        <div className='d-block d-sm-none'>
          {items?.map((item: any, idx: number) => (
            <div className='border card card-flush h-md-50 mb-5 mb-xl-10'>
              <div className='card-header pt-3 px-5'>
                <div className='card-title d-flex flex-column me-0 w-100'>
                  <div className='align-items-center d-flex mb-2 w-100'>
                    <span className='fs-2 fw-bold lh-1 text-dark flex-grow-1'>
                      {getFormattedItem(item, formObject.listFields[0])}
                    </span>
                    <span>
                      <Dropdown className='float-end'>
                        <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
                          <span className='svg-icon svg-icon-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24px'
                              height='24px'
                              viewBox='0 0 24 24'
                            >
                              <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                                <rect x={5} y={5} width={5} height={5} rx={1} fill='currentColor' />
                                <rect
                                  x={14}
                                  y={5}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                                <rect
                                  x={5}
                                  y={14}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                                <rect
                                  x={14}
                                  y={14}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                              </g>
                            </svg>
                          </span>
                        </Dropdown.Toggle>

                        {formObject?.updateEndpoint && (
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className='py-2 fw-semibold'
                              onClick={() => {
                                setItemToEdit(item)
                                setShowItemModal(true)
                              }}
                            >
                              <span className='pe-1 pt-1'>Edit</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className='py-2 fw-semibold'
                              onClick={() => {
                                Swal.fire({
                                  text: `Are you sure you want to delete this item?`,
                                  icon: 'error',
                                  confirmButtonText: 'Yes, Delete It',
                                  confirmButtonColor: 'red',
                                  showConfirmButton: false,
                                  showCancelButton: true,
                                  showDenyButton: true,
                                  denyButtonText: 'Yes, Delete',
                                  cancelButtonText: 'Cancel',
                                }).then(async ({isDenied}) => {
                                  if (isDenied) {
                                    let response
                                    let err
                                    try {
                                      response = await axios.delete(
                                        `${formObject.updateEndpoint}/${item?.id}`
                                      )
                                      console.log(response?.data?.data)
                                    } catch (error: any) {
                                      err = error
                                      response = error?.response
                                    }

                                    if (response?.data?.success) {
                                      Swal.fire({
                                        text: 'Deleted!',
                                        icon: 'success',
                                      })
                                      await getItems()
                                    } else {
                                      Swal.fire({
                                        title: 'Error occured!',
                                        text: `${response?.data?.error?.message}`,
                                        icon: 'error',
                                      })
                                    }
                                  }
                                })
                              }}
                            >
                              <span className='pe-1 pt-1'>Delete</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                    </span>
                  </div>

                  {formObject.listFields.map((a: any, index: number) => {
                    if (index < 1) return
                    return (
                      <div
                        key={`${item.id}-${a.name}-${index}`}
                        className='align-items-center d-flex'
                      >
                        <span className='min-w-80px fs-6 fw-bold me-2 pt-1'>
                          {loading ? <Skeleton count={1} /> : <>{a.header}: </>}
                        </span>
                        <span className='fs-6 fw-normal pt-1'>
                          {loading ? <Skeleton count={1} /> : getFormattedItem(item, a)}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='d-none d-sm-block card card-flush'>
          <div className='card-body pt-0 overflow-auto'>
            <table
              className=' table align-middle table-row-dashed fs-6 gy-2 '
              id='kt_ecommerce_category_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  {formObject.listFields.map((a: any, index: number) => {
                    return (
                      <th key={index} className='' style={{minWidth: a.minWidth}}>
                        <div className='d-flex gap-1'>
                          <span>{a.header}</span>
                          {a.tooltip && (
                            <span style={{textTransform: 'none'}}>
                              {/* <ReactTooltip
                                id={`${a.name}-header-tooltip`}
                                place='top'
                                effect='solid'
                              >
                                {a.tooltip}
                              </ReactTooltip> */}

                              <span
                                data-tip
                                data-for={`${a.name}-header-tooltip`}
                                data-padding='5px 7px'
                                // title={a.tooltip}
                              >
                                <img src={toAbsoluteUrl('/media/icons/info-icon.svg')} alt='' />
                              </span>
                            </span>
                          )}
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>

              <tbody className='fw-semibold'>
                {items?.map((item: any, idx: number) => {
                  return (
                    <tr key={idx}>
                      {/* td items */}
                      {formObject.listFields.map((a: any, index: number) => {
                        return (
                          <td key={`${item.id}-${a.name}-${index}`}>
                            {loading ? <Skeleton count={1} /> : getFormattedItem(item, a)}
                          </td>
                        )
                      })}

                      {/* ACTIONS */}
                      <td className='text-start'>
                        <Dropdown className='float-end'>
                          <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
                            <span className='svg-icon svg-icon-2'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                              >
                                <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                                  <rect
                                    x={5}
                                    y={5}
                                    width={5}
                                    height={5}
                                    rx={1}
                                    fill='currentColor'
                                  />
                                  <rect
                                    x={14}
                                    y={5}
                                    width={5}
                                    height={5}
                                    rx={1}
                                    fill='currentColor'
                                    opacity='0.3'
                                  />
                                  <rect
                                    x={5}
                                    y={14}
                                    width={5}
                                    height={5}
                                    rx={1}
                                    fill='currentColor'
                                    opacity='0.3'
                                  />
                                  <rect
                                    x={14}
                                    y={14}
                                    width={5}
                                    height={5}
                                    rx={1}
                                    fill='currentColor'
                                    opacity='0.3'
                                  />
                                </g>
                              </svg>
                            </span>
                          </Dropdown.Toggle>

                          {formObject?.updateEndpoint && (
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className='py-2 fw-semibold'
                                onClick={() => {
                                  setItemToEdit(item)
                                  setShowItemModal(true)
                                }}
                              >
                                <span className='pe-1 pt-1'>Edit</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className='py-2 fw-semibold'
                                onClick={() => {
                                  Swal.fire({
                                    text: `Are you sure you want to delete this item?`,
                                    icon: 'error',
                                    confirmButtonText: 'Yes, Delete It',
                                    confirmButtonColor: 'red',
                                    showConfirmButton: false,
                                    showCancelButton: true,
                                    showDenyButton: true,
                                    denyButtonText: 'Yes, Delete',
                                    cancelButtonText: 'Cancel',
                                  }).then(async ({isDenied}) => {
                                    if (isDenied) {
                                      let response
                                      let err
                                      try {
                                        response = await axios.delete(
                                          `${formObject.updateEndpoint}/${item?.id}`
                                        )
                                        console.log(response?.data?.data)
                                      } catch (error: any) {
                                        err = error
                                        response = error?.response
                                      }

                                      if (response?.data?.success) {
                                        Swal.fire({
                                          text: 'Deleted!',
                                          icon: 'success',
                                        })
                                        await getItems()
                                      } else {
                                        Swal.fire({
                                          title: 'Error occured!',
                                          text: `${response?.data?.error?.message}`,
                                          icon: 'error',
                                        })
                                      }
                                    }
                                  })
                                }}
                              >
                                <span className='pe-1 pt-1'>Delete</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </InfiniteScroll>
      {itemToEdit ? (
        <AddUpdateItemModal
          formObject={formObject}
          inputData={{...itemToEdit}}
          show={showItemModal}
          handleClose={() => handleEditItemClose()}
          handleSaved={async () => {
            handleEditItemClose()
            await getItems()
          }}
        />
      ) : null}
    </>
  )
}

export default ManageItem
