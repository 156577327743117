import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function Companies() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const formObject = {
    listEndpoint: `${API_URL}/admin/company`,
    listTitle: 'Companies',
    listFields: [
      {
        name: 'name',
        header: 'Name',
        type: 'link',
        path: '/companies',
      },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: 'date',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
    addEndpoint: `${API_URL}/admin/company`,
    addTitle: 'Add Company',
    addFields: [
      {
        name: 'name',
        label: 'Name',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          required: true,
          minCharacters: 2,
        },
        type: 'text',
      },
    ],
    updateEndpoint: `${API_URL}/admin/company`,
    updateTitle: 'Edit Company',
    updateFields: [
      {
        name: 'name',
        label: 'Name',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          required: true,
          minCharacters: 2,
        },
        type: 'text',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
