/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      {/* DASHBOARD */}
      <AsideMenuItem
        to='dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title='Dashboard'
        fontIcon='bi-layers'
      />
      {/* FIND WORK */}
      <AsideMenuItem
        to='admin-users'
        icon='/media/icons/duotune/general/gen025.svg'
        title='Admin'
        fontIcon='bi-layers'
      />
      {/* COMPANIES */}
      <AsideMenuItem
        to='companies'
        icon='/media/icons/duotune/general/gen025.svg'
        title='Companies'
        fontIcon='bi-layers'
      />
    </>
  )
}
