/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Field} from 'formik'
import ReactTooltip from 'react-tooltip'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
  touched: any
}

const InputTextAreaWithLabel = ({
  field,
  data,
  updateData,
  hasError,
  errors,
  touched,
}: InputComponentProps) => {
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }

  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`d-flex align-items-center fs-7 fw-bold mb-0 ms-1 ${
          field.validation.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top' effect='solid'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <Field name={field.name}>
        {({field, form, meta}: any) => (
          <div>
            <textarea
              id={`input-${field.name}-id`}
              rows={3}
              {...field}
              placeholder={field.placeholder}
              className='form-control form-control-lg form-control-solid'
              name='appname'
              value={data && data[field.name]}
              onChange={(e) => {
                let objectValue = {} as any
                objectValue[field.name as string] = e.target.value
                setInputValue(e.target.value)
                updateData(objectValue)
                form.setFieldValue(field.name as string, e.target.value)
              }}
            ></textarea>
            {/* <ErrorMessage name={field.name} /> */}

            {touched[field.name] && errors[field.name] && (
              <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>
      {/* <textarea
        rows={3}
        className="form-control form-control-lg form-control-solid"
        name="appname"
        placeholder=""
        value={data && data[field.name]}
        onChange={(e) =>
          onChange(e)
        }
      ></textarea>
      {!inputValue && hasError && (
        <div className="fv-plugins-message-container">
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block"
          >
            {field.label} is required
          </div>
        </div>
      )} */}
    </div>
  )
}

export {InputTextAreaWithLabel}
