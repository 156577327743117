import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import AdminUsers from './components/AdminUsers';

const adminUsersBreadCrumbs: Array<PageLink> = [
	{
		title: "Dashboard",
		path: "",
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const AdminUsersPage: React.FC = () => {
	return (
		<Routes>
			<Route
				element={
					<div id="kt_app_content" className="app-content flex-column-fluid">
						<div id="kt_app_content_container" className="app-container container-xxl">
							<div className="card card-flush">
								<Outlet />
							</div>
						</div>
					</div>
				}
			>
				<Route
					path=""
					element={
						<>
							<PageTitle breadcrumbs={adminUsersBreadCrumbs}>Admin Users</PageTitle>
							<AdminUsers />
						</>
					}
				/>
			</Route>
		</Routes>
	);
};

export default AdminUsersPage;
