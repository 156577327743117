/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Field} from 'formik'
import ReactTooltip from 'react-tooltip'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
}

const DatePickerInputWithLabel = ({
  field,
  data,
  updateData,
  hasError,
  errors,
}: InputComponentProps) => {
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const [defaultValue, setDefaultValue] = useState<any>()
  const [startDate, setStartDate] = useState(
    data[field.name] ? new Date(data[field.name]) : undefined
  )
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }

  const [loading, setLoading] = useState<boolean>(false)

  const getList = () => {
    return axios.get<any>(`${field.optionsEndpoint}`)
  }

  const getData = async () => {
    var {data} = await getList()
    return data
  }

  const [items, setItems] = useState<Array<any>>([])

  //let defaultValue: undefined | any
  useEffect(() => {
    console.log()
    if (field.optionsEndpoint) {
      setLoading(true)
      getList().then(({data: response}) => {
        console.log('data', response)
        setItems(response.data)

        const _defaultValue =
          response?.data?.length &&
          response.data.map((item: any, index: number) => {
            return {
              label: item.title ?? item.name,
              value: item.id,
            }
          })[response.data.findIndex((a: any) => a.id === (data && data[field.name]))]
        setDefaultValue(_defaultValue)
        setLoading(false)
      })
    } else {
      setItems(field.options)

      const _defaultValue =
        field.options &&
        field.options.map((item: any, index: number) => {
          return {
            label: item.title ?? item.name,
            value: item.id,
          }
        })[field.options.findIndex((a: any) => a.id === (data && data[field.name]))]

      setDefaultValue(_defaultValue)
    }
  }, [])

  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`form-label fs-7 fw-bold mb-0 ms-1 ${
          field.validation.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top' effect='solid'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <Field name={field.name}>
        {({field, form, meta}: any) => (
          <div>
            <DatePicker
              {...field}
              id={`input-${field.name}-id`}
              value={startDate ? moment(startDate).format('MMM D, yyyy') : undefined}
              selected={startDate}
              className='form-control form-control-lg fw-semibold'
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              name={field.name}
              onChange={(date: Date) => {
                let objectValue = {} as any
                objectValue[(field.name ?? field.title) as string] = date.toISOString()
                updateData(objectValue)

                form.setFieldValue((field.name ?? field.title) as string, date.toISOString())
                //form.setTouched({...form.touched,[(field.name ?? field.title)]: false });
                setStartDate(date)
              }}
            />

            {errors[field.name] && (
              <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>

      <div className='text-danger mt-2'>{/* <ErrorMessage name="role" /> */}</div>
    </div>
  )
}

export {DatePickerInputWithLabel}
