/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef, useState} from 'react'
import axios from 'axios'
import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useNavigate, Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {KTSVG} from '../../helpers'
import {Form, Formik} from 'formik'
import {InputDropdownWithLabel} from '../molecules/InputDropdownWithLabel'
import {InputRichTextWithLabel} from '../molecules/InputRichTextWithLabel'
import {InputTextAreaWithLabel} from '../molecules/InputTextAreaWithLabel'
import {InputTextWithLabel} from '../molecules/InputTextWithLabel'
import {DatePickerInputWithLabel} from '../molecules/DatePickerInputWithLabel'
import {DateTimePickerInputWithLabel} from '../molecules/DateTimePickerInputWithLabel'
import {InputMultiselectDropdownWithLabel} from '../molecules/InputMultiselectDropdownWithLabel'
import {TimePickerInputWithLabel} from '../molecules/TimePickerInputWithLabel'
import {InputPasswordWithLabel} from '../molecules/InputPasswordWithLabel'
import {InputPhoneNumberWithLabel} from '../molecules/InputPhoneNumberWithLabel'
import {InputCodeWithLabel} from '../molecules/InputCodeWithLabel'
import { getValidationSchema } from '../../helpers/utils/GetValidationSchema'
import { FileUploadInputWithLabel } from '../molecules/FileUploadInputWithLabel'

type Props = {
  formObject: any
  inputData?: any
  errors?: Array<any>
  touched?: any
  onSubmit: Function
  loading?: boolean
}

const getFieldComponent = (
  field: any,
  data: any,
  updateData: any,
  hasError: any,
  errors: any,
  touched: any,
  setErrors: Function,
) => {
  let fieldComponent
  switch (field.type) {
    case 'text':
      fieldComponent = (
        <InputTextWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'password':
      fieldComponent = (
        <InputPasswordWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'phone-number':
      fieldComponent = (
        <InputPhoneNumberWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'code':
      fieldComponent = (
        <InputCodeWithLabel
          key={field.name}
          fields={field.fields}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'textarea':
      fieldComponent = (
        <InputTextAreaWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'dropdown':
      fieldComponent = (
        <InputDropdownWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'multiselect-dropdown':
      fieldComponent = (
        <InputMultiselectDropdownWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'richtext':
      fieldComponent = (
        <InputRichTextWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'datepicker':
      fieldComponent = (
        <DatePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'datetimepicker':
      fieldComponent = (
        <DateTimePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'timepicker':
      fieldComponent = (
        <TimePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'file-upload':
      fieldComponent = (
        <FileUploadInputWithLabel
          key={field.name}
          touched={touched}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          setError={setErrors}
        />
      )
      break
    default:
      break
  }
  return fieldComponent
}

const AppForm: FC<Props> = ({formObject, inputData, onSubmit, loading}) => {
  //const [loading, setLoading] = useState(false)
  
  const navigate = useNavigate()

  const [data, setData] = useState<any>({
    ...inputData,
  } as any)
  const [hasError, setHasError] = useState(false)

  const updateData = (fieldsToUpdate: Partial<any>) => {
    console.log('updateData data', data)
    console.log('updateData fieldsToUpdate', fieldsToUpdate)
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }


  return (
    <div className=''>
        <Formik
          initialValues={
            {
              ...data,
            } as any
          }
          validationSchema={getValidationSchema(formObject.formFields)}
          onSubmit={(values) => {
            // same shape as initial values
            console.log('values', values)
            onSubmit(values)
          }}
        >
          {({errors, touched, isValid, isSubmitting, setErrors}) => (
            <Form>
              <div className='w-100'>
                <div className='row fv-row'>
                  {formObject.formFields?.map((field: any) => {
                    return getFieldComponent(field, data, updateData, hasError, errors, touched, setErrors)
                  })}
                </div>
              </div>
              {formObject?.helpText && (
                <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
                  <div />
                  <Link
                    to={formObject?.helpText.redirectUrl}
                    className='link-primary'
                    data-kt-translate='sign-in-forgot-password'
                    style={{marginLeft: '5px'}}
                  >
                    {formObject?.helpText.title}
                  </Link>
                </div>
              )}
              <div
                className={`${
                  formObject?.cancelButtonUrl ? 'd-flex flex-stack' : 'text-start'
                }`}
              >
                <button
                  disabled={!isValid || loading}
                  className={`btn ${isValid || loading ? 'btn-primary' : 'btn-secondary'}`}
                  type='submit'
                >
                  <span>{formObject?.buttonTitle || 'Submit'}</span>
                  {loading ? (
                    <div className='ms-4 spinner-border spinner-border-sm' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                </button>
                {formObject?.cancelButtonUrl && (
                  <Link to={formObject?.cancelButtonUrl}>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-lg btn-light-primary fw-bold'
                      //   disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Cancel
                    </button>
                  </Link>
                )}
              </div>
            </Form>
          )}
        </Formik>
    </div>
  )
}

export default AppForm
