import {FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../../_metronic/layout/admin/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../_metronic/helpers'
import Companies from '../../modules/companies/Companies'
import CompanyPage from '../../modules/companies/CompanyPage'
import AdminUsersPage from '../../modules/admin/AdminUsersPage'

import {Logout} from '../../../web-app-core'

const AdminPrivateRoutes = () => {
  //const {currentUser, auth} = useAuth()

  //console.log('TalentPrivateRoutes currentUser', currentUser)
  //console.log('TalentPrivateRoutes auth', auth)
  const {pathname} = useLocation()
  console.log('TalentPrivateRoutes pathname', pathname)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path='companies' element={<Companies />} />
        <Route path='companies/:id' element={<CompanyPage />} />
        <Route path='admin-users' element={<AdminUsersPage />} />
        <Route path='logout' element={<Logout />} />
        <Route path='*' element={<Navigate to={'companies'} />} />
        <Route index element={<Navigate to={'companies'} />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {AdminPrivateRoutes}
