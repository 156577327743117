/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import {ErrorsPage} from '../../modules/errors/ErrorsPage'
import {AdminPrivateRoutes} from './AdminPrivateRoutes'
import {AdminApp} from '../../AdminApp'
import {AuthPage, Logout, useAuth} from '../../../web-app-core'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AdminAppRoutes: FC = () => {
  const {currentUser, auth} = useAuth()
  console.log('AdminAppRoutes auth', auth)

  const userType = 'admin'

  const loginFormObject = {
    addTitle: 'Sign In',
    description: 'Get to build project from an idea to a functional working product.',
    helpText: {
      title: 'Forgot password ?',
      redirectUrl: `/${userType}/auth/forgot-password`,
    },
    addEndpoint: `${process.env.REACT_APP_API_URL}/${userType}/account/login`,
    formFields: [
      {
        name: 'emailAddress',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: {
          required: true,
          email: true,
          message: 'Wrong email format',
        },
        type: 'text',
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        validation: {
          required: true,
          min: 3,
          message: 'Minimum 3 characters',
        },
        type: 'password',
      },
    ],
    userStatus: 'AccountCreated',
    onboardingUrl: `/${userType}/onboarding`,
    dashboardUrl: '/',
    errorUrl: `/${userType}/auth/registration/confirm`,
    forgotPasswordUrl: `/${userType}/auth/forgot-password`,
    userExistUrl: `/${userType}/auth/registration/confirm`,
  }

  return (
    <Routes>
      <Route element={<AdminApp />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        {/* <Route path='admin/*' element={<AdminPrivateRoutes />} /> */}
        {auth ? (
          <>
            {/* Talent Pages */}
            <Route path='*' element={<AdminPrivateRoutes />} />
            <Route index element={<Navigate to='companies' />} />
          </>
        ) : (
          <>
            {/* Talent User Not Authenticated */}
            <Route
              path={`${userType}/auth/*`}
              element={
                <AuthPage
                  userType={userType}
                  loginFormObject={loginFormObject}
                  bgUrl='/media/auth/DCSL-auth-bg.png'
                />
              }
            />
            <Route path='*' element={<Navigate to={`${userType}/auth`} />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

export {AdminAppRoutes}
