import React, {useState} from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import axios from 'axios'
import Swal from 'sweetalert2'
import AddUpdateItemForm from './AddUpdateItemForm'

interface EmailVerificationProps {
  userType: string
  formObject?: any
}
interface LocationState {
  email: string
}

const EmailVerification = ({userType, formObject: inputFormObject}: EmailVerificationProps) => {
  const [loadResend, setLoadResend] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {email} = location.state as LocationState

  if (!email) {
    navigate('/')
  }

  const API_URL = process.env.REACT_APP_API_URL
  const VERIFY_EMAIL_URL = `${process.env.REACT_APP_API_URL}/${userType}/account/confirm-account`
  const RESEND_VERIFY_EMAIL_URL = `${API_URL}/${userType}/account/signup-resend`

  const defaultFormObject = {
    addTitle: 'Email Verification',
    description: `Enter the verification code we sent to ${email}`,
    addEndpoint: VERIFY_EMAIL_URL,
    addFields: [
      {
        name: 'emailAddress',
        value: email,
        type: 'hidden',
      },
      {
        name: 'confirmationCode',
        label: 'Confirmation Code',
        placeholder: 'Confirmation Code',
        fields: 6,
        validation: {
          required: true,
          min: 6,
          message: 'Minimum 6 characters',
        },
        type: 'code',
      },
    ],
    resendEndpoint: RESEND_VERIFY_EMAIL_URL,
    redirectUrl: `/${userType}/auth/login`,
    loginUrl: `/${userType}/auth/login`,
  }

  const formObject = inputFormObject ?? defaultFormObject

  const resendConfirmation = async () => {
    setLoadResend(true)
    await axios
      .post(formObject?.resendEndpoint, {emailAddress: email})
      .then(async (res: any) => {
        toast.success(res.data.message)
      })
      .catch((err) => {
        toast.error('Failed to resend confirmation code')
      })
      .finally(() => setLoadResend(false))
  }

  const submitted = (_: any, __: any, response: any) => {
    Swal.fire({
      text: `${response?.data?.message}`,
      icon: 'success',
      confirmButtonText: 'Okay',
    })

    navigate(formObject.redirectUrl)
  }

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <Link to={formObject?.loginUrl} className='btn btn-icon bg-light rounded-circle'>
            <span className='svg-icon svg-icon-2 svg-icon-gray-800'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z'
                  fill='currentColor'
                />
                <path
                  opacity='0.3'
                  d='M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </Link>
        </div>

        <div className='m-0'>
          <span className='text-gray-400 fw-bold fs-5 me-2' data-kt-translate='two-step-head-desc'>
            Didn&apos;t get the code ?
          </span>
          <span
            style={{cursor: loadResend ? 'not-allowed' : 'pointer'}}
            className='link-primary fw-bold fs-5'
            data-kt-translate='two-step-head-resend'
            onClick={() => resendConfirmation()}
          >
            {loadResend ? 'Resending...' : 'Resend'}
            {loadResend && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
          </span>
        </div>
      </div>

      <div className='py-20'>
        <AddUpdateItemForm formObject={formObject} submitted={submitted} />
      </div>
    </>
  )
}

export default EmailVerification
