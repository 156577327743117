import {ManageItem, TableList} from '../../../../web-app-core'


const API_URL = process.env.REACT_APP_API_URL

export default function AdminUsers() {

  const formObject = {
    addEndpoint: `${API_URL}/admin/user`,
    addTitle: 'Add Admin User',
    addFields: [
      {
        name: "firstName",
        label: "First Name",
        toolTip: "Enter First Name",
        placeholder: "",
        validation: {
          type: "string",
          required: true,
          minLength: 3,
        },
        type: "text",
        cols: 6,
      },
      {
        name: "lastName",
        label: "Last Name",
        toolTip: "Enter Last Name",
        placeholder: "",
        validation: {
          type: "string",
          required: true,
          minLength: 3,
        },
        type: "text",
        cols: 6,
      },
      {
        name: "emailAddress",
        label: "Email Address",
        toolTip: "Enter Email Address",
        placeholder: "",
        validation: {
          type: "string",
          required: true,
          matches: "[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+",
          message: "Invalid email format",
        },
        type: "text",
      },
    ],
    updateTitle: "Admin Users",
    updateEndpoint: ``,
    updateFields: [],
    listEndpoint: `${API_URL}/admin/user`,
    listTitle: 'Admin Users',
    listFields: [
      {
        name: 'name',
        header: 'Name',
      },
      {
        name: 'emailAddress',
        header: 'Email Address',
      },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: "date",
      },
      {
        name: 'userRole',
        header: 'User Role',
      },
      {
        name: 'status',
        header: 'Status',
        type: "status",
        
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
