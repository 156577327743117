/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef, useState} from 'react'
import axios from 'axios'
import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {KTSVG} from '../../helpers'
import {Form, Formik} from 'formik'
import {InputDropdownWithLabel} from '../molecules/InputDropdownWithLabel'
import {InputRichTextWithLabel} from '../molecules/InputRichTextWithLabel'
import {InputTextAreaWithLabel} from '../molecules/InputTextAreaWithLabel'
import {InputTextWithLabel} from '../molecules/InputTextWithLabel'
import {DatePickerInputWithLabel} from '../molecules/DatePickerInputWithLabel'
import {DateTimePickerInputWithLabel} from '../molecules/DateTimePickerInputWithLabel'
import {InputMultiselectDropdownWithLabel} from '../molecules/InputMultiselectDropdownWithLabel'
import {TimePickerInputWithLabel} from '../molecules/TimePickerInputWithLabel'
import {InputPhoneNumberWithLabel} from '../molecules/InputPhoneNumberWithLabel'
import { FileUploadInputWithLabel } from '../molecules/FileUploadInputWithLabel'

type Props = {
  show: boolean
  handleClose: () => void
  handleSaved: () => void
  formObject: any
  inputData?: any
  errors?: Array<any>
  touched?: any
  setErrors?: Function
}

const getFieldComponent = (
  field: any,
  data: any,
  updateData: any,
  hasError: any,
  errors: any,
  touched: any,
  setErrors?: Function,
) => {
  let fieldComponent
  switch (field.type) {
    case 'text':
      fieldComponent = (
        <InputTextWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'textarea':
      fieldComponent = (
        <InputTextAreaWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'dropdown':
      fieldComponent = (
        <InputDropdownWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'multiselect-dropdown':
      fieldComponent = (
        <InputMultiselectDropdownWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'richtext':
      fieldComponent = (
        <InputRichTextWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'datepicker':
      fieldComponent = (
        <DatePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'datetimepicker':
      fieldComponent = (
        <DateTimePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'timepicker':
      fieldComponent = (
        <TimePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'phone-number':
      fieldComponent = (
        <InputPhoneNumberWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
      case 'file-upload':
        fieldComponent = (
          <FileUploadInputWithLabel
            key={field.name}
            touched={touched}
            field={field}
            data={data}
            updateData={updateData}
            hasError={hasError}
            errors={errors}
            setError={setErrors as any}
          />
        )
        break
    default:
      break
  }
  return fieldComponent
}

const AddUpdateItemModal: FC<Props> = ({show, handleClose, handleSaved, formObject, inputData}) => {
  const modalsRoot = document.getElementById('root-modals') || document.body

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const closeModal = useRef<any>()

  const [data, setData] = useState<any>({
    ...inputData,
  } as any)
  const [hasError, setHasError] = useState(false)

  const updateData = (fieldsToUpdate: Partial<any>) => {
    console.log('updateData data', data)
    console.log('updateData fieldsToUpdate', fieldsToUpdate)
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const fieldsToRender = inputData?.id ? formObject.updateFields : formObject.addFields
  const formTitle = inputData?.id ? formObject.updateTitle : formObject.addTitle

  const getValidationSchema = () => {
    let yupObject = {} as any
    let yupValidation = {} as any
    if (inputData?.id) {
      formObject.updateFields.map((field: any) => {
        switch (field.validation['type']) {
          case 'number':
            yupObject[field.name] = Yup.number().typeError(`${field.label} must be a number`)
            break

          case 'string':
            yupObject[field.name] = Yup.string()
            break

          default:
            if (!field.validation['type']) {
              yupObject[field.name] = Yup.string()
            }
            break
        }

        Object.keys(field.validation).map((item) => {
          switch (item) {
            case 'required':
              yupObject[field.name] = yupObject[field.name].required(
                `${field.label} is a required field`
              )
              break
            case 'matches':
              yupObject[field.name] = yupObject[field.name].matches(
                field.validation[item],
                field.validation['message']
              )
              break
            default:
              break
          }
        })
      })
    } else {
      formObject.addFields?.map((field: any) => {
        switch (field.validation['type']) {
          case 'number':
            yupObject[field.name] = Yup.number().typeError(`${field.label} must be a number`)
            break

          case 'string':
            yupObject[field.name] = Yup.string()
            break

          default:
            if (!field.validation['type']) {
              yupObject[field.name] = Yup.string()
            }
            break
        }

        Object.keys(field.validation).map((item) => {
          switch (item) {
            case 'required':
              yupObject[field.name] = yupObject[field.name].required(
                `${field.label} is a required field`
              )
              break
            case 'matches':
              yupObject[field.name] = yupObject[field.name].matches(
                field.validation[item],
                field.validation['message']
              )
              break
            default:
              break
          }
        })
      })
    }

    console.log('yupObject', yupObject)
    return Yup.object().shape(yupObject)
  }

  const submitData = async (values: any) => {
    console.log('values to save', values)

    setLoading(true)

    let response
    try {
      if (inputData?.id) {
        const objectData = {} as any
        formObject.updateFields.forEach((item: any) => {
          if (item.notAllowed) {
            delete objectData[item.value]
          } else if (item?.type === 'hidden') {
            objectData[item.name] = item.value
          } else {
            objectData[item.name] = values[item.name]
          }
        })

        console.log('objectDataEd', objectData)
        response = await axios({
          method: formObject.updateMethod ? formObject.updateMethod : 'patch',
          url: `${formObject.updateEndpoint}/${inputData?.id}`,
          data: objectData,
        })
      } else {
        const objectData = {} as any
        formObject.addFields.forEach((item: any) => {
          if (item.notAllowed) {
            delete objectData[item.value]
          } else if (item?.type === 'hidden') {
            objectData[item.name] = item.value
          } else {
            objectData[item.name] = values[item.name]
          }
        })

        console.log('objectData', objectData)

        response = await axios.post(formObject.addEndpoint, objectData)
      }
    } catch (error: any) {
      response = error?.response
    } finally {
      setLoading(false)
    }

    console.log('submitData response', response)
    setLoading(false)
    if (response?.data.success) {
      closeModal.current.click()
      handleSaved()
      Swal.fire({
        text: `Item succesfully ${inputData ? 'updated' : 'added'}`,
        icon: 'success',
        confirmButtonText: 'Okay',
      }).then(() => {})
    } else {
      Swal.fire({
        text: `${response?.data?.error?.message}`,
        icon: 'error',
        confirmButtonText: 'Okay',
      })
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-dialog mw-600px m-0'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end pt-3 px-4'>
            <div
              onClick={handleClose}
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              ref={closeModal}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y  pt-0 '>
            <div className='text-center mb-7'>
              <h1 className='mb-3'>{formTitle}</h1>
              {/* 
              <div className="text-muted fw-bold fs-5">
                If you need more info, please check out
                <a href="#" className="link-primary fw-bolder">
                  {" "}
                  FAQ Page
                </a>
                .
              </div> */}
            </div>
            <div>
              <Formik
                initialValues={
                  {
                    ...data,
                  } as any
                }
                validationSchema={getValidationSchema()}
                onSubmit={(values) => {
                  // same shape as initial values
                  console.log('values', values)
                  submitData(values)
                }}
              >
                {({errors, touched, setErrors}) => (
                  <Form>
                    <div className='w-100'>
                      <div className='row fv-row mb-10'>
                        {fieldsToRender?.map((field: any) => {
                          return getFieldComponent(
                            field,
                            data,
                            updateData,
                            hasError,
                            errors,
                            touched,
                            setErrors
                          )
                        })}
                      </div>
                    </div>
                    <div className='text-center'>
                      <button
                        disabled={loading}
                        onClick={
                          () => {}
                          // submitData()
                        }
                        className='btn btn-primary'
                        type='submit'
                      >
                        <span>Save</span>
                        {loading ? (
                          <div className='ms-4 spinner-border spinner-border-sm' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                        ) : null}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export default AddUpdateItemModal
