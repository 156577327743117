/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {ErrorMessage, Field} from 'formik'
import ReactTooltip from 'react-tooltip'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
  touched: any
}

const InputPhoneNumberWithLabel = ({
  field,
  data,
  updateData,
  hasError,
  errors,
  touched,
}: InputComponentProps) => {
  // console.log('InputTextWithLabel data', data)
  const country = field?.defaultCountry ?? 'us'
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const onChange = (number: any, form: any) => {
    let objectValue = {} as any
    objectValue[(field.name ?? field.title) as string] = '+' + number
    form.setFieldValue((field.name ?? field.title) as string, '+' + number)
    updateData(objectValue)
    setInputValue(number)

    console.log('objectValue', objectValue)
    console.log('number', number)
  }
  // console.log('errors', field.name)
  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`d-flex align-items-center fs-7 fw-bold mb-0 ms-1 ${
          field.validation.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top' effect='solid'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <Field name={field.name}>
        {({field, form, meta}: any) => (
          <div>
            <PhoneInput
              // {...field}
              country={country}
              inputProps={{
                id: `input-${field.name}-id`,
              }}
              // countryCodeEditable={false}
              enableSearch={true}
              value={inputValue}
              disableSearchIcon={true}
              buttonClass='w-50px'
              inputClass={`form-control form-control-lg form-control-solid ps-18 fw-semibold `}
              inputStyle={{width: '100%'}}
              containerStyle={{fontFamily: 'inherit'}}
              onChange={(num) => onChange(num, form)}
            />

            {touched[field.name] && errors[field.name] && (
              <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>
      {!inputValue && hasError && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            {field.label} is required
          </div>
        </div>
      )}
    </div>
  )
}

export {InputPhoneNumberWithLabel}
