import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

// Talent Endpoints
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const TALENT_GET_USER_PROFILE = `${API_URL}/talent/profile`
export const TALENT_REFRESH_TOKEN_URL = `${API_URL}/talent/account/refresh-token`
export const TALENT_LOGIN_URL = `${API_URL}/talent/account/login`
export const TALENT_REGISTER_URL = `${API_URL}/talent/account`
export const TALENT_VERIFY_EMAIL_URL = `${API_URL}/talent/account/confirm-account`
export const TALENT_REQUEST_PASSWORD_URL = `${API_URL}/talent/account/password-forgot`
export const TALENT_RESET_PASSWORD_URL = `${API_URL}/talent/account/password-reset`

export const GET_TALENT_PROFILE_URL = `${API_URL}/talent/profile`
export const GET_COMPANY_PROFILE_URL = `${API_URL}/company/profile/user`
export const GET_COMPANY_URL = `${API_URL}/admin/company`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(TALENT_LOGIN_URL, {
    emailAddress: email,
    password,
  })
}

// Server should return AuthModel
export function refreshToken(refreshToken: string) {
  return axios.post(TALENT_REFRESH_TOKEN_URL, {
    refreshToken
  })
}

// Server should return AuthModel
export function register(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password: string,
) {
  return axios.post(TALENT_REGISTER_URL, {
    firstName: firstName,
    lastName: lastName,
    emailAddress: email,
    phoneNumber: `+${phone}`,
    password,
  })
}

export function verifyEmail(
  emailAddress: string,
  confirmationCode: string,
) {
  return axios.post(TALENT_VERIFY_EMAIL_URL, {
    emailAddress,
    confirmationCode
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(TALENT_REQUEST_PASSWORD_URL, {
    emailAddress: email,
  })
}

export function resetPassword(email: string, passwordResetCode: string, newPassword: string) {
  return axios.post(TALENT_RESET_PASSWORD_URL, {
    emailAddress: email,
    passwordResetCode,
    newPassword
  })
}

export function getTalentProfile() {
  return axios.get(GET_TALENT_PROFILE_URL)
}

export function getCompanyProfile() {
  return axios.get(GET_COMPANY_PROFILE_URL)
}

export function getCompany(id: string) {
  return axios.get(`${GET_COMPANY_URL}/${id}`)
}

export function getUserProfile() {
  return axios.get<{data: UserModel}>(TALENT_GET_USER_PROFILE)
}
