import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../web-app-core'
import {useParams} from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URL

export default function CompanyUsers() {
  const navigate = useNavigate()
  const {id} = useParams()

  const formObject = {
    listEndpoint: `${API_URL}/company/${id}/user`,
    listTitle: 'Company Board Members',
    listFields: [
      {
        name: 'user.name',
        header: 'Board Member',
      },
      {
        name: 'user.emailAddress',
        header: 'Email Address',
      },
      {
        name: 'user.phoneNumber',
        header: 'Phone Number',
      },
      {
        name: 'boardRole',
        header: 'Board Role',
      },
      {
        name: 'userRole',
        header: 'User Role',
      },
      {
        name: 'dateCreated',
        header: 'Date Added',
        type: 'date',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'tag',
      },
    ],
    addEndpoint: `${API_URL}/company/${id}/user`,
    addTitle: 'Add Company User',
    addFields: [
      {
        name: 'firstName',
        label: 'First Name',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          minCharacters: 2,
        },
        type: 'text',
        cols: 6,
      },
      {
        name: 'lastName',
        label: 'Last Name',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          minCharacters: 2,
        },
        type: 'text',
        cols: 6,
      },
      {
        name: 'emailAddress',
        label: 'Email Address',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          email: true,
          message: 'Wrong email format',
        },
        type: 'text',
      },
      // {
      //   name: 'phoneNumber',
      //   label: 'Phone Number',
      //   toolTip: '',
      //   placeholder: '',
      //   validation: {
      //     min: 10,
      //     message: 'Invalid phone number',
      //   },
      //   type: 'phone-number',
      //   defaultCountry: 'ng',
      // },
      {
        name: 'boardRole',
        label: 'Board Role',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'Board Chairman', title: 'Board Chairman'},
          {id: 'Board Secretary', title: 'Board Secretary'},
          {id: 'Board Member', title: 'Board Member'},
        ],
      },
      {
        name: 'userRole',
        label: 'User Role',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'User', title: 'User'},
          {id: 'Admin', title: 'Admin'}
        ],
      },
    ],
    updateEndpoint: `${API_URL}/company/${id}/user`,
    updateTitle: 'Update Company User',
    updateFields: [
      {
        name: 'firstName',
        label: 'First Name',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          minCharacters: 2,
        },
        type: 'text',
        cols: 6,
      },
      {
        name: 'lastName',
        label: 'Last Name',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          minCharacters: 2,
        },
        type: 'text',
        cols: 6,
      },
      {
        name: 'boardRole',
        label: 'Board Role',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'Board Chairman', title: 'Board Chairman'},
          {id: 'Board Secretary', title: 'Board Secretary'},
          {id: 'Board Member', title: 'Board Member'},
        ],
      },
      {
        name: 'userRole',
        label: 'User Role',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'User', title: 'User'},
          {id: 'Admin', title: 'Admin'}
        ],
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
