/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../../context/Auth'
import {GetFormattedFieldItem, GetStatusCssClassColor} from '../../helpers'

interface DetailHeaderProps {
  formObject: any
  id: string
}

const DetailHeader: React.FC<DetailHeaderProps> = ({formObject, id}) => {
  const location = useLocation()
  const {currentUser} = useAuth()
  const [item, setItem] = useState<any>({})
  console.log(currentUser)
  const getObject = async () => {
    const response = await axios.get(`${formObject.endpoint}`)
    setItem(response.data.data)
  }

  useEffect(() => {
    console.log('location', location)
    getObject()
  }, [location])

  return (
    <div className='card mb-3 mb-xl-5'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex pb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-wrap fw-bold fs-6 pe-2 mb-2'>
                  <Link to='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-5'>
                    {item?.name ?? item.title}
                  </Link>
                  <div>
                    <span
                      className={`badge badge-light-${GetStatusCssClassColor(
                        item?.status
                      )} fw-bolder me-auto`}
                    >
                      {item?.status}
                    </span>
                  </div>
                </div>

                {item?.description && (
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                      {item?.description}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-wrap justify-content-start'>
              <div className='d-flex flex-wrap'>
                {formObject.dataFields.map((field: any) => {
                  return (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center fs-4 fw-bold'>
                        {GetFormattedFieldItem(item, field)}
                      </div>

                      <div className='fw-semibold fs-6 text-gray-400'>{field.label}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className='separator'></div>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {formObject.tabs.map((tab: any) => {
              return (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === tab.path && 'active')
                    }
                    to={tab.path}
                  >
                    {tab.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DetailHeader
